import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import {
  ALL_COLORS,
  ALL_PRODUCTS,
  GET_MORE_PRODUCTS,
  PRODUCT_LOADER,
  SET_AUTH_LOADING,
  SET_GET_MORE_PRODUCTS_LOADER,
  SET_GET_PRODUCTS_LOADER,
  SET__PRODUCTS_LAST_VISIBLE,
  SINGLE_PRODUCT,
} from "../types";

let productCollection = firebase.firestore().collection("products");

export const setGetProductsLoader = (val) => async (dispatch) => {
  dispatch({ type: SET_GET_PRODUCTS_LOADER, payload: val });
};
export const setGetMoreProductsLoader = (val) => async (dispatch) => {
  dispatch({ type: SET_GET_MORE_PRODUCTS_LOADER, payload: val });
};
export const setProductLastVisible = (val) => async (dispatch) => {
  dispatch({ type: SET__PRODUCTS_LAST_VISIBLE, payload: val });
};
export const getAllProducts = (showPerPage) => async (dispatch) => {
  dispatch(setGetProductsLoader(true));

  await productCollection
    .orderBy("createdAt", "desc")
    .limit(showPerPage)
    .onSnapshot(
      (query) => {
        const products = [];

        for (let doc of query.docs) {
          products.push({
            id: doc.id,
            ...doc.data(),
          });
        }

        dispatch({
          type: ALL_PRODUCTS,
          payload: products,
        });

        // Store the reference to the last document
        if (products.length > 0) {
          dispatch(setProductLastVisible(query.docs[query.docs.length - 1]));
        }
        if (products?.length === 0 || products?.length < showPerPage) {
          dispatch(setProductLastVisible(null));
        }
        dispatch(setGetProductsLoader(false));
      },
      (err) => {
        dispatch(setGetProductsLoader(false));
      }
    );
};

export const getMoreProducts =
  (showPerPage, startAfter) => async (dispatch) => {
    dispatch(setGetMoreProductsLoader(true));

    await productCollection
      .orderBy("createdAt", "desc")
      .startAfter(startAfter)
      .limit(showPerPage)
      .onSnapshot(
        (query) => {
          const products = [];

          for (let doc of query.docs) {
            products.push({
              id: doc.id,
              ...doc.data(),
            });
          }

          dispatch({
            type: GET_MORE_PRODUCTS,
            payload: products,
          });

          // Store the reference to the last document
          if (products.length > 0) {
            dispatch(setProductLastVisible(query.docs[query.docs.length - 1]));
          }
          if (products?.length === 0 || products?.length < showPerPage) {
            dispatch(setProductLastVisible(null));
          }
          dispatch(setGetMoreProductsLoader(false));
        },
        (err) => {
          dispatch(setGetMoreProductsLoader(false));
        }
      );
  };

export const getSingleProduct = (id) => async (dispatch) => {
  dispatch(setLoader(true));
  try {
    const snapshot = await productCollection.doc(id).get();

    if (snapshot.exists) {
      const data = { id: snapshot.id, ...snapshot.data() };
      dispatch({
        type: SINGLE_PRODUCT,
        payload: data,
      });
    } else {
      // Handle the case where the document doesn't exist
      console.log("Document does not exist");
    }
  } catch (error) {
    console.error("Error fetching single product:", error);
  } finally {
    dispatch(setLoader(false));
  }
};
export const setLoader = (val) => async (dispatch) => {
  dispatch({ type: PRODUCT_LOADER, payload: val });
};

////////////////
export const getAllColors = (showPerPage) => async (dispatch) => {
  dispatch(setGetProductsLoader(true));

  await firebase
    .firestore()
    .collection("colors")

    .onSnapshot(
      (query) => {
        const colors = [];

        for (let doc of query.docs) {
          colors?.push({
            id: doc.id,
            ...doc.data(),
          });
        }

        dispatch({
          type: ALL_COLORS,
          payload: colors,
        });

        // Store the reference to the last document

        // dispatch(setGetProductsLoader(false));
      },
      (err) => {
        // dispatch(setGetProductsLoader(false));
      }
    );
};
