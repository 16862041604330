import axios from "axios";
const baseDomain = "https://us-central1-b2b--platform.cloudfunctions.net/app";
const baseURL = `${baseDomain}/api`;

let authToken = localStorage.getItem("authToken");

let axiosOptions = {
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
    "Content-Type": "application/json",
    ...(authToken && { Authorization: "Bearer " + authToken }),
  },
};

export default axios.create(axiosOptions);
