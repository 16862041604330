import { toast } from "react-toastify";
import firebase from "../../config/firebase";
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, SET_AUTH_LOADING } from "../types";

let usersCollection = firebase.firestore().collection("users");

export const setAuthLoading = (val) => async (dispatch) => {
  dispatch({ type: SET_AUTH_LOADING, payload: val });
};

export const signUp =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(setAuthLoading(true));

    firebase
      .auth()
      .createUserWithEmailAndPassword(payload.email, payload.password)
      .then(async (data) => {
        let { password, confirmPassword, applicantDetails, ...rest } = payload;
        if (applicantDetails?.signature) {
          const downloadURL = await uploadSignature(applicantDetails.signature);
          applicantDetails.signature = downloadURL;
        }

        usersCollection
          .doc(data.user.uid)
          .set({
            ...rest,
            applicantDetails: applicantDetails,
            role: "wholeSeller",
            createdAt: firebase.firestore.Timestamp.now(),
            acceptStatus: "pending",
          })
          .then((res) => {
            dispatch(setAuthLoading(false));
            toast.success(
              "Request has been sent you can login once admin approve your request!"
            );
            onSuccess();
          })
          .catch((err) => {
            dispatch(setAuthLoading(false));
            toast.error(err?.message);
          });
      })
      .catch((err) => {
        dispatch(setAuthLoading(false));

        toast.error(err?.message);
      });
  };

export const login = (payload) => async (dispatch) => {
  dispatch(setAuthLoading(true));
  let unsubscribe = null;
  firebase
    .auth()
    .signInWithEmailAndPassword(payload.email, payload.password)
    .then((userCredential) => {
      let user = userCredential.user.uid;
      unsubscribe = usersCollection.doc(user).onSnapshot(async (query) => {
        if (query.data()) {
          let { acceptStatus } = query.data();
          if (acceptStatus?.toLowerCase() == "accepted") {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { id: query.id, ...query.data() },
            });
          } else if (acceptStatus?.toLowerCase() == "rejected") {
            toast.warning("Your request has been rejected");
            dispatch(logout());
            unsubscribe();
          } else if (acceptStatus?.toLowerCase() == "pending") {
            toast.warning("Your request is still pending");
            dispatch(logout());
            unsubscribe();
          } else {
            toast.warning("Your account data have been deleted from database");
            dispatch(logout());
            unsubscribe();
          }
          dispatch(setAuthLoading(false));
        } else {
          toast.warning("Your account data have been deleted from database");

          dispatch(setAuthLoading(false));
          dispatch(logout());
          unsubscribe();
        }
      });
    })
    .catch((err) => {
      dispatch(setAuthLoading(false));
      toast.error(err?.message);
    });
};

export const logout = () => async (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then((data) => {
      localStorage.clear();
      dispatch({ type: LOGOUT_SUCCESS, payload: null });
    })
    .catch((error) => {
      dispatch({ type: LOGOUT_SUCCESS, payload: null });
    });
};
export const forgetPassword =
  (email, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(setAuthLoading(true));
    try {
      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then((res) => {
          dispatch(setAuthLoading(false));
          toast.success("Please check your email and reset the password");
          onSuccess();
        })
        .catch((err) => {
          dispatch(setAuthLoading(false));
          console.error(err.message);
        });
    } catch (error) {
      dispatch(setAuthLoading(false));
      console.error(error.message);
    }
  };

const uploadSignature = async (signature) => {
  const storageRef = firebase.storage().ref();
  const filename = `${Date.now()}`;
  const imageRef = storageRef.child(`images/${filename}`);
  const snapshot = await imageRef.put(signature);
  return await snapshot.ref.getDownloadURL();
};
