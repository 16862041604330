import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "reactstrap";
import hairbulk from "../../assets/img/hairbulk.png";
import { useDispatch, useSelector } from "react-redux";
import { getAllCarts, deleteCartItem } from "../../store/actions/cartAction";
import { QUANTITY_CHANGE, UPDATE_TOTAL } from "../../store/types";
import { getAllColors } from "../../store/actions/productAction";

const Cart = ({ handleNextClick, uid }) => {
  const { colors, colorLoader } = useSelector((state) => state.product);

  const dispatch = useDispatch();
  const { carts, loader, totalAmount } = useSelector((state) => state.cart);

  const [deleteItemId, setDeleteItemId] = useState("");

  const increaseQuantity = (itemIndex, qty) => {
    let updatedCart = [...carts];

    updatedCart[0].items[itemIndex].quantity = qty;
    dispatch({ type: QUANTITY_CHANGE, payload: updatedCart });
    updateTotal();
  };

  const decreaseQuantity = (itemIndex, qty) => {
    const updatedCart = [...carts];
    if (updatedCart[0].items[itemIndex].quantity > 1) {
      updatedCart[0].items[itemIndex].quantity = qty;
      dispatch({ type: QUANTITY_CHANGE, payload: updatedCart });
      updateTotal();
    }
  };

  const updateTotal = () => {
    let total = 0;
    carts[0]?.items.forEach((item) => {
      total += item.price * item.quantity;
    });
    dispatch({ type: UPDATE_TOTAL, payload: total });
  };

  const delItem = (productId, deduct) => {
    dispatch({ type: UPDATE_TOTAL, payload: totalAmount - deduct });
    setDeleteItemId(productId);
    dispatch(
      deleteCartItem(uid, productId, () => {
        setDeleteItemId("");
      })
    );
  };
  useEffect(() => {
    updateTotal();
    dispatch(getAllColors());
  }, []);

  return (
    <>
      <p className="checkout__title mb-0 my-md-4 my-2 text-dark">Cart</p>
      <Table className="custom-table " responsive>
        <thead>
          <tr>
            <th>Name</th>
            <th>Image</th>
            <th>Price</th>
            <th className="text-center">Quantity</th>
            <th>Color</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {carts?.map((cart) =>
            cart?.items?.map((data, idx) => {
              let curentColor = colors?.find(
                (color) => color?.id === data.color
              );

              return (
                <tr key={idx}>
                  <td className="align-middle">{data?.name}</td>
                  <td className="align-middle">
                    <div className="custom-table__img p-1">
                      <img src={data?.image} alt={data?.name} />
                    </div>
                  </td>
                  <td className="align-middle">{data?.price}$</td>

                  <td className="align-middle custom-table__quantity ">
                    <div className="d-flex w-100  justify-content-center align-items-center">
                      <i
                        className="fa fa-minus text-dark mr-2 cursor-pointer"
                        onClick={() => decreaseQuantity(idx, data.quantity - 1)}
                      ></i>{" "}
                      <h5 className="mb-0  mx-3">{data.quantity} </h5>
                      <i
                        className="fa fa-plus ml-2 cursor-pointer"
                        onClick={() => increaseQuantity(idx, data.quantity + 1)}
                      ></i>
                    </div>
                  </td>
                  <td className="align-middle">
                    {" "}
                    <div className="custom-table__img p-1">
                      {" "}
                      <img src={curentColor?.url} />{" "}
                    </div>{" "}
                  </td>
                  <td className="align-middle text-center">
                    <Button
                      disabled={loader && deleteItemId === data.productId}
                      className="  custom-table__btn px-3  text-dark"
                      onClick={() =>
                        delItem(data.productId, data.quantity * data.price)
                      }
                    >
                      {loader && deleteItemId === data.productId ? (
                        <Spinner sm={"sm"}> </Spinner>
                      ) : (
                        "Delete"
                      )}
                    </Button>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </Table>
      <div className="d-flex flex-wrap justify-content-end align-items-center  gap-3">
        <p className="text-center mb-0  text-dark checkout__total my-md-3 my-2">
          <span className="fw--700">Total:</span> {totalAmount && totalAmount}$
        </p>
        <Button
          onClick={handleNextClick}
          className="  custom-table__btn px-md-5 px-3 ml-3  text-dark fw--600 my-md-3 my-2"
        >
          Continue to Details & Payment
        </Button>
      </div>
      <hr />
    </>
  );
};

export default Cart;
