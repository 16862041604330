import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";

import location from "../../assets/img/location.png";
import {
  CardNumberElement,
  Elements,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import VISA from "../../assets/img/visa.svg";
import MASTERCARD from "../../assets/img/MasterCard_Logo.svg.png";
import AMERICANEXPRESS from "../../assets/img/americanExpress.png";
import DISCOVER from "../../assets/img/Discover-logo.png";
import UNIONPAY from "../../assets/img/UnionPay.png";
import DINERSCLUB from "../../assets/img/DindersClub.svg";
import JCB from "../../assets/img/JCBNEW.png";
import { loadStripe } from "@stripe/stripe-js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { CART_LOADER } from "../../store/types";
import LoadingComponent from "../common/LoadingComponent.";
import Repository from "../../repository/Repository";

const stripePromise = loadStripe(process.env.REACT_APP_Stripe_Key);

const DetailsAndPayment = ({
  HandleCheckOut,
  promoApplied,
  discountDetails,
  setDiscountDetails,
  setPromoApplied,
}) => {
  const dispatch = useDispatch();
  const { user, cardDetails } = useSelector((state) => state.authUser);
  const { totalAmount, loader } = useSelector((state) => state.cart);
  const [isEditing, setIsEditing] = useState(false);
  const { isGettingCardDetails } = useSelector((state) => state.auth);
  const [promoCode, setPromoCode] = useState("");
  const [isApplyingPromo, setIsApplyingPromo] = useState(false);

  const clearDiscount = () => {
    setPromoApplied(false);
    setDiscountDetails({});
  };

  const applyPromoCode = async () => {
    try {
      setIsApplyingPromo(true);
      let { data } = await Repository.post("/payment/apply-coupon", {
        coupon: promoCode,
        totalAmount,
      });
      console.log("Data", data);
      if (data?.success) {
        setDiscountDetails(data?.data);
        setPromoApplied(true);
        toast.success("Promo code applied!");
      } else {
        toast("Invalid promo code!");
      }
      setIsApplyingPromo(false);
    } catch (error) {
      clearDiscount();
      setIsApplyingPromo(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const initialData = {
    name: user?.name || "",
    telephone: user?.telephone || "",
    address: user?.address || "",
  };
  const [details, setDetails] = useState(initialData);
  const handleDetailsChange = (e) => {
    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    e.preventDefault();

    dispatch({ type: CART_LOADER, payload: true });

    if (cardDetails) {
      HandleCheckOut("");
    }

    if (!stripe || !elements) {
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    if (cardNumberElement && cardExpiryElement && cardCvcElement) {
      const { token, error } = await stripe.createToken(cardNumberElement, {
        name: details?.name, // Include cardholder's name
      });

      console.log("token: ", token);
      if (error) {
        toast.error(error?.message);

        return;
      } else {
        HandleCheckOut(token);
        // toast.success("Order placed!");
      }
    }
  };

  const getIcon = (brand) => {
    switch (brand) {
      case "MasterCard":
        return MASTERCARD;
      case "American Express":
        return AMERICANEXPRESS;
      case "Discover":
        return DISCOVER;
      case "UnionPay":
        return UNIONPAY;
      case "Diners Club":
        return DINERSCLUB;
      case "JCB":
        return JCB;
      default:
        return VISA;
    }
  };

  return (
    <>
      <p className="checkout__title mb-0 my-md-4 my-2 text-dark">
        Details & Payment
      </p>

      {isGettingCardDetails ? (
        <div className="d-flex py-4 px-3 justify-content-center">
          <Spinner />
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <div className="checkout__payment p-3 p-md-4">
            <Row>
              <Col md="6">
                <FormGroup>
                  <Label className="checkout__label">Name</Label>
                  <Input
                    placeholder="Enter you name"
                    name="name"
                    value={details?.name}
                    required
                    onChange={handleDetailsChange}
                  />
                </FormGroup>
              </Col>
              {!cardDetails && (
                <>
                  <Col md="6">
                    <FormGroup className="d-flex flex-column ">
                      <label className="checkout__label">Card Number</label>
                      <CardNumberElement
                        options={{ placeholder: "4242 4242 4242 4242" }}
                        className="form-control "
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label className="checkout__label">Expiration Date</label>
                      <div>
                        <CardExpiryElement className="form-control " />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup className="d-flex flex-column ">
                      <label className="checkout__label">CVV</label>
                      <CardCvcElement
                        options={{ placeholder: "CVV", maxLength: "4" }}
                        className="form-control form-control-solid "
                      />
                    </FormGroup>
                  </Col>
                </>
              )}

              <Col md="6">
                <FormGroup>
                  <Label className="checkout__label">Phone Number</Label>
                  <Input
                    type="number"
                    placeholder="Enter you phone number"
                    name="telephone"
                    value={details?.telephone}
                    required
                    onChange={handleDetailsChange}
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <FormGroup>
                  <Label className="checkout__label">Address</Label>
                  <div className="w-100 position-relative">
                    <Input
                      type="text"
                      placeholder="Enter you address"
                      className="text-dark w-100 auth__icon-field"
                      name="address"
                      value={details?.address}
                      required
                      onChange={handleDetailsChange}
                    />
                    {/* <img className="auth__icon" src={location} /> */}
                  </div>
                </FormGroup>
              </Col>

              <Col md="12" className="d-flex align-items-end">
                <FormGroup>
                  <Label className="checkout__label">Promo Code</Label>
                  <div className="w-100 position-relative">
                    <Input
                      type="text"
                      placeholder="Enter promo code"
                      className="text-dark w-100 auth__icon-field"
                      name="promoCode"
                      value={promoCode}
                      onChange={(e) => {
                        setPromoCode(e.target.value);
                      }}
                      disabled={promoApplied || isApplyingPromo}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (promoApplied) {
                            setPromoApplied(false);
                            setPromoCode("");
                            setDetails({});
                          } else {
                            applyPromoCode();
                          }
                        }
                      }}
                    />
                  </div>
                </FormGroup>
                <div>
                  <Button
                    className="checkout__btn px-md-5 p py-2 rounded text-dark border-0 ml-2 promo-btn "
                    type="button"
                    disabled={promoCode == "" || isApplyingPromo}
                    onClick={() => {
                      if (promoApplied) {
                        setPromoApplied(false);
                        setPromoCode("");
                        setDetails({});
                      } else {
                        applyPromoCode();
                      }
                    }}
                  >
                    {isApplyingPromo ? (
                      <Spinner size="sm" />
                    ) : promoApplied ? (
                      "Try New"
                    ) : (
                      "Apply"
                    )}
                  </Button>
                </div>{" "}
              </Col>

              {cardDetails && (
                <Col sm="12">
                  <FormGroup>
                    <Label className="checkout__label">
                      <h5>Payment Details</h5>
                    </Label>
                    <div className="w-100 border border-site-primary rounded-lg  d-flex flex-sm-row flex-column justify-content-between align-items-start align-items-sm-center p-2 p-md-3 mb-2">
                      <div className="d-flex  align-items-center">
                        {" "}
                        <div>
                          <img
                            src={getIcon(cardDetails?.brand)}
                            alt=""
                            height={50}
                            width={50}
                            className="stripe__card-icon"
                          ></img>{" "}
                        </div>
                        <h5 className="mb-0 ml-2">{cardDetails?.name}</h5>
                      </div>
                      <p className="mb-0">
                        <b>**** **** ****</b>
                        {cardDetails?.last4}
                      </p>
                    </div>
                    <Link to="/my-profile" className="float-right">
                      change card?
                    </Link>
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row className="justify-content-start mt-3">
              <Col md="6">
                <p className="text-center mb-0  text-dark checkout__total ">
                  <span className="fw--700">Total Amount:</span>{" "}
                  <span className={`${promoApplied ? "promo-applied" : ""}`}>
                    ${totalAmount}
                  </span>{" "}
                  {promoApplied
                    ? `$${discountDetails?.discountedAmount}`
                    : null}
                </p>
              </Col>
              <Col
                md="6"
                className="d-flex justify-content-center mt-md-0 mt-2"
              >
                <Button
                  className="checkout__btn px-5 py-2 rounded text-dark border-0 w-100"
                  type="submit"
                  disabled={loader}
                >
                  {loader ? <Spinner size={"sm"}> </Spinner> : "Checkout"}
                </Button>
              </Col>
            </Row>
          </div>{" "}
        </Form>
      )}
    </>
  );
};

export default DetailsAndPayment;
