import CardRepository from "./CardRepository";
import OrderRepository from "./OrderRepository";
import StatesRepository from "./StatesRepository";
import UserRepository from "./UserRepository";

const repositories = {
  statesAndCities: StatesRepository,
  order: OrderRepository,
  card: CardRepository,
  user: UserRepository,
};
export const RepositoryFactory = {
  get: (name) => repositories[name],
};
