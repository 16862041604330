import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Auth = (props) => {
  const { uid } = useSelector((state) => state.authUser);
  const history = useHistory();
  if (uid) {
    history.push("/dashboard");
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Header /> {props.children}
    </>
  );
};

export default Auth;
