import React from "react";
import { Col, Container, Row } from "reactstrap";
import instagram from "../assets/img/instagram.png";
import facebook from "../assets/img/facebook.png";
import massenger from "../assets/img/massenger.png";
import whatsapp from "../assets/img/whatsapp.png";
import { useHistory } from "react-router-dom";
import CustomLogo from "./CustomLogo";

const Footer = () => {
  const history = useHistory();
  const handleRedirect = (url) => {
    history.push(url);
  };
  return (
    <div className="bg-site-primary py-md-5 py-3 main-footer">
      <Container>
        <Row noGutters className="justify-content-between">
          <Col
            md="4"
            // lg="2"
            className="d-flex flex-column justify-content-center align-items-start"
          >
            {/* <p className="m-0 main-footer__b2b">THESSA</p> */}
            <CustomLogo />
            <p className="mb-4 main-footer__sub-header text-dark">
              Stay Connected
            </p>

            <div className="d-flex  ">
              <img src={facebook} className="mr-3 cursor-pointer" />
              {/* <img src={massenger} className="mr-3 cursor-pointer" /> */}

              <img src={instagram} className="mr-3 cursor-pointer" />

              {/* <img src={whatsapp} className="" /> */}
            </div>
          </Col>

          <Col md="4" className="mt-md-0 mt-2">
            <p className="mb-3 main-footer__heading text-dark  ">
              Customer Care
            </p>
            <ul>
              <li className="cursor-pointer main-footer__link ">Reach us at</li>
              <li
                className="cursor-pointer main-footer__link mb-1"
                onClick={() => {
                  const mailtoLink = `mailto:support@thessa.us`;

                  window.location.href = mailtoLink;
                }}
              >
                support@thessa.us
              </li>
              {/* <li className="cursor-pointer main-footer__link mb-1">
                Help Center
              </li>
              <li className="cursor-pointer main-footer__link mb-1">
                Transaction Services Agreement for non-EU/UK Consumers{" "}
              </li> */}

              {/* <li className="cursor-pointer main-footer__link mb-1">
                Terms and Conditions for EU/EEA/UK{" "}
              </li>

              <li className="cursor-pointer main-footer__link mb-1">
                Consumers (Transactions)
              </li>

              <li className="cursor-pointer main-footer__link ">
                Take our feedback survey{" "}
              </li> */}
            </ul>
          </Col>
          <Col md="4" className="mt-md-0 mt-2">
            <p className=" main-footer__heading text-dark  mb-3">
              {/* Shopping With Us */}
              Site Map
            </p>
            <ul>
              <li
                className="cursor-pointer main-footer__link mb-1"
                onClick={() => {
                  handleRedirect("/product");
                }}
              >
                Products
              </li>
              <li
                className="cursor-pointer main-footer__link mb-1"
                onClick={() => {
                  handleRedirect("/retailers");
                }}
              >
                Locate Retailers
              </li>
              <li
                className="cursor-pointer main-footer__link mb-1"
                onClick={() => {
                  handleRedirect("/about-us");
                }}
              >
                About Us
              </li>
              <li
                className="cursor-pointer main-footer__link mb-1"
                onClick={() => {
                  handleRedirect("/contact-us");
                }}
              >
                Contact Us
              </li>
              {/* <li className="cursor-pointer main-footer__link mb-1">
                Making payments{" "}
              </li>
              <li className="cursor-pointer main-footer__link mb-1">
                Delivery options{" "}
              </li>

              <li className="cursor-pointer main-footer__link mb-1">
                Buyer Protection{" "}
              </li>

              <li className="cursor-pointer main-footer__link mb-1">
                Terms and Conditions for EU/EEA/UK{" "}
              </li>

              <li className="cursor-pointer main-footer__link mb-1">
                Consumers (Transactions)
              </li>

              <li className="cursor-pointer main-footer__link ">
                Take our feedback survey{" "}
              </li> */}
            </ul>
          </Col>
          {/* <Col md="6" lg="3" className="mt-md-0 mt-2">
            <p className=" main-footer__heading text-dark  mb-3">
              Collaborate With Us{" "}
            </p>
            <ul>
              <li className="cursor-pointer main-footer__link mb-1">
                Partnerships{" "}
              </li>
              <li className="cursor-pointer main-footer__link mb-1">
                Affiliate program{" "}
              </li>

              <li className="cursor-pointer main-footer__link ">DS Center </li>
            </ul>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
