import { useHistory } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const Main = (props) => {
  const { uid } = useSelector((state) => state.authUser);
  const history = useHistory();
  if (!uid) {
    history.push("/login");
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="d-flex flex-column " style={{ minHeight: "100vh" }}>
      <Header />
      <div style={{ flexGrow: 1 }}>{props.children}</div>
      <Footer />
    </div>
  );
};

export default Main;
